import React from "react";
import { graphql } from "gatsby";
import Contact from "@views/Contact";
import { ContactProps } from "src/types";

export const pageQuery = graphql`
  query ContactQuery {
    bgDesktop: file(relativePath: { eq: "bg/desktop/contact-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1542, maxHeight: 1028, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgMobile: file(relativePath: { eq: "bg/mobile/contact-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 695, maxHeight: 1157, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    btnWA: file(relativePath: { eq: "btn-wa.png" }) {
      childImageSharp {
        fluid(maxWidth: 372, maxHeight: 66, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function ContactRoot(props: ContactProps) {
  return <Contact {...props} />;
}
